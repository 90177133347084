import {
  Accordion,
  AccordionDetails,
  Container,
  makeStyles,
} from "@material-ui/core"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import EditClubAccordion from "./EditClubAccordion"

const useStyle = makeStyles(theme => ({
  root: {
    backgroundColor: "transparent",
    border: "none",
    position: "inherit",
    boxShadow: "none",
    width: "100%",
    justifyContent: "center",
    ".MuiCollapse-entered": {
      padding: 0,
      margiLeft: "-26px",
    },
  },
  summaryRoot: {
    marginLeft: "-27px",
    padding: 0,
    width: "100%",
  },
}))

function EditClubItem({ setClubInfo, clubInfo, setIsDisabled }) {
  const classes = useStyle()

  const user = useSelector(state => state.userSlice.user)
  const club = user.userInfo?.openingHours

  const [weekTimes, setWeekTimes] = useState({
    monday: {
      start: club?.monday?.start,
      end: club?.monday?.end,
      isChecked: club?.monday?.isChecked,
    },
    tuesday: {
      start: club?.tuesday?.start,
      end: club?.tuesday?.end,
      isChecked: club?.tuesday?.isChecked,
    },
    wednesday: {
      start: club?.wednesday?.start,
      end: club?.wednesday?.end,
      isChecked: club?.wednesday?.isChecked,
    },
    thursday: {
      start: club?.thursday?.start,
      end: club?.thursday?.end,
      isChecked: club?.thursday?.isChecked,
    },
    friday: {
      start: club?.friday?.start,
      end: club?.friday?.end,
      isChecked: club?.friday?.isChecked,
    },
    saturday: {
      start: club?.saturday?.start,
      end: club?.saturday?.end,
      isChecked: club?.saturday?.isChecked,
    },
    sunday: {
      start: club?.sunday?.start,
      end: club?.sunday?.end,
      isChecked: club?.sunday?.isChecked,
    },
    holidays: {
      start: club?.holidays?.start,
      end: club?.holidays?.end,
      isChecked: club?.holidays?.isChecked,
    },
  })

  const setWeekTime = (day, start, value) => {
    let formatedDay = { ...weekTimes[day] }
    formatedDay[start ? "start" : "end"] = value
    setWeekTimes({ ...weekTimes, [day]: formatedDay })
    setClubInfo({
      ...clubInfo,
      ...{ openingHours: { ...clubInfo.openingHours, [day]: formatedDay } },
    })
  }
  const removeDay = (day, value) => {
    let formatedDay = { start: "", end: "", isChecked: value }
    setClubInfo({
      ...clubInfo,
      ...{ openingHours: { ...clubInfo.openingHours, [day]: formatedDay } },
    })
    setWeekTimes({ ...weekTimes, [day]: formatedDay })
  }

  return (
    <Container maxWidth="lg">
      <Accordion expanded={true} className={classes.root}>
        <AccordionDetails
          className={classes.summaryRoot}
          style={{ width: "100%", marginRight: "224px" }}
        >
          <div>
            <EditClubAccordion
              setIsDisabled={setIsDisabled}
              label="Monday"
              removeDay={removeDay}
              start={weekTimes.monday.start}
              end={weekTimes.monday.end}
              setWeekTime={(start, value) => {
                setWeekTime("monday", start, value)
              }}
              isChecked={weekTimes.monday.isChecked}
            />
            <EditClubAccordion
              setIsDisabled={setIsDisabled}
              label="Tuesday"
              removeDay={removeDay}
              start={weekTimes.tuesday.start}
              end={weekTimes.tuesday.end}
              setWeekTime={(start, value) => {
                setWeekTime("tuesday", start, value)
              }}
              isChecked={weekTimes.tuesday.isChecked}
            />
            <EditClubAccordion
              setIsDisabled={setIsDisabled}
              label="Wednesday"
              removeDay={removeDay}
              start={weekTimes.wednesday.start}
              end={weekTimes.wednesday.end}
              setWeekTime={(start, value) => {
                setWeekTime("wednesday", start, value)
              }}
              isChecked={weekTimes.wednesday.isChecked}
            />
            <EditClubAccordion
              setIsDisabled={setIsDisabled}
              label="Thursday"
              removeDay={removeDay}
              start={weekTimes.thursday.start}
              end={weekTimes.thursday.end}
              setWeekTime={(start, value) => {
                setWeekTime("thursday", start, value)
              }}
              isChecked={weekTimes.thursday.isChecked}
            />
            <EditClubAccordion
              setIsDisabled={setIsDisabled}
              label="Friday"
              removeDay={removeDay}
              start={weekTimes.friday.start}
              end={weekTimes.friday.end}
              setWeekTime={(start, value) => {
                setWeekTime("friday", start, value)
              }}
              isChecked={weekTimes.friday.isChecked}
            />
            <EditClubAccordion
              setIsDisabled={setIsDisabled}
              label="Saturday"
              removeDay={removeDay}
              start={weekTimes.saturday.start}
              end={weekTimes.saturday.end}
              setWeekTime={(start, value) => {
                setWeekTime("saturday", start, value)
              }}
              isChecked={weekTimes.saturday.isChecked}
            />
            <EditClubAccordion
              setIsDisabled={setIsDisabled}
              label="Sunday"
              removeDay={removeDay}
              start={weekTimes.sunday.start}
              end={weekTimes.sunday.end}
              setWeekTime={(start, value) => {
                setWeekTime("sunday", start, value)
              }}
              isChecked={weekTimes.sunday.isChecked}
            />
            <EditClubAccordion
              setIsDisabled={setIsDisabled}
              label="Holidays"
              removeDay={removeDay}
              start={weekTimes.holidays.start}
              end={weekTimes.holidays.end}
              setWeekTime={(start, value) => {
                setWeekTime("holidays", start, value)
              }}
              isChecked={weekTimes.holidays.isChecked}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </Container>
  )
}

export default EditClubItem
